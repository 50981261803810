import { css } from '@emotion/react';

import * as sectionStyles from '@/components/genreHome/sections/index.styles';
import { between, BreakPoint, greaterThan, orBelow } from '@/components/styles/media';

const GENREHOME_SECTION_MAX_WIDTH = 1169;

const LIST_PADDING_DESKTOP = 30;
export const LIST_PADDING_MOBILE = 16;

const MOBILE_ITEM_PADDING = 8;
const DESKTOP_ITEM_PADDING = 6;
const MAX_LIMIT = 3;
const MID_LIMIT = 2;

export const arrowContainerStyle = css`
  ${sectionStyles.horizontalSectionArrowContainerStyle};

  & > *:first-of-type {
    transform: translate(${DESKTOP_ITEM_PADDING / 2}px);
  }

  & > *:last-of-type {
    transform: translate(-${DESKTOP_ITEM_PADDING / 2}px);
  }
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      & > *:first-of-type {
        transform: translate(${MOBILE_ITEM_PADDING / 2}px);
      }

      & > *:last-of-type {
        transform: translate(-${MOBILE_ITEM_PADDING / 2}px);
      }
    `,
  )};
`;

export const sliderContainerStyle = css`
  margin-left: ${-DESKTOP_ITEM_PADDING / 2}px;
  margin-right: ${-DESKTOP_ITEM_PADDING / 2}px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-left: ${-MOBILE_ITEM_PADDING / 2}px;
      margin-right: ${-MOBILE_ITEM_PADDING / 2}px;
    `,
  )}
`;

export const listStyle = css`
  display: flex;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-right: 10px;
    `,
  )}
`;

export const listItemStyle = css`
  padding-left: ${DESKTOP_ITEM_PADDING / 2}px;
  padding-right: ${DESKTOP_ITEM_PADDING / 2}px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: ${MOBILE_ITEM_PADDING / 2}px;
      padding-right: ${MOBILE_ITEM_PADDING / 2}px;
    `,
  )}

  ${greaterThan(
    BreakPoint.DesktopMedium,
    css`
      width: calc(
        (${GENREHOME_SECTION_MAX_WIDTH + 1}px - ${LIST_PADDING_DESKTOP * 2 - DESKTOP_ITEM_PADDING}px) / ${MAX_LIMIT}
      );
    `,
  )};

  ${between(
    BreakPoint.DesktopSmall,
    BreakPoint.DesktopMedium,
    css`
      width: calc((100vw - ${LIST_PADDING_DESKTOP * 2 - DESKTOP_ITEM_PADDING}px) / ${MID_LIMIT});
    `,
  )};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: calc((100vw - ${LIST_PADDING_MOBILE * 2 - MOBILE_ITEM_PADDING}px));
    `,
  )};
`;
