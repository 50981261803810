import { DetailedHTMLProps, forwardRef, ImgHTMLAttributes, useMemo, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { mergeRefs } from '@/utils/mergeRefs';

interface ImageWithHandlerProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  onLoad?: () => Any;
  onError?: () => Any;
}

const handleImageComplete = (img: HTMLImageElement, onLoad?: () => Any, onError?: () => Any) => {
  if (img.naturalWidth > 0) {
    onLoad?.();
  } else {
    onError?.();
  }
};

export const ImageWithHandler = forwardRef<HTMLImageElement, ImageWithHandlerProps>(
  ({ alt, onLoad, onError, ...props }, forwardedRef): ReactJSX.Element => {
    const ref = useRef<HTMLImageElement>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useIsomorphicLayoutEffect(() => {
      const img = ref.current;

      if (!img) {
        setImageLoaded(false);
        return;
      }

      if (img.complete) {
        setImageLoaded(true);
      } else {
        img.onload = () => setImageLoaded(true);

        img.onerror = () => setImageLoaded(false);
      }
    }, []);

    useIsomorphicLayoutEffect(() => {
      const img = ref.current;
      if (img && imageLoaded) {
        handleImageComplete(img, onLoad, onError);
      }
    }, [imageLoaded, onLoad, onError]);

    const mergedRefs = useMemo(() => mergeRefs([ref, forwardedRef]), [forwardedRef]);
    return <img ref={mergedRefs} alt={alt} {...props} />;
  },
);
