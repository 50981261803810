import { css, SerializedStyles } from '@emotion/react';
import { useMemo } from 'react';

import { useSectionTrackingDataContext } from '../SectionTrackingDataContextProvider/SectionTrackingDataContextProvider';
import { Slider } from '../Slider';
import * as styles from './ListLayoutWithColorBox.styles';

type ListLayoutWithColorBoxSectionItemType = {
  id: number | string;
};

interface ListLayoutWithColorBoxSectionProps<T> {
  items: T[];
  renderItem: (props: { item: T; index: number }) => ReactJSX.Element;
  arrowContainerCss?: SerializedStyles;
}

/**
 * @description Item의 요소들이 color box를 사용하는 섹션 레이아웃 컴포넌트
 *
 * ex) SelectionCarousel, EventHorizontal
 * @example
 * const MySection = () =>
 * <SectionLayout>
 *  <ListLayoutWithColorBox items={item} renderItem={renderItem} />
 * </SectionLayout>;
 */

export const ListLayoutWithColorBox = <T extends ListLayoutWithColorBoxSectionItemType>({
  items,
  renderItem,
  arrowContainerCss,
}: ListLayoutWithColorBoxSectionProps<T>): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const arrowContainerStyle = useMemo(() => css([styles.arrowContainerStyle, arrowContainerCss]), [arrowContainerCss]);

  return (
    <Slider
      arrowContainerCss={arrowContainerStyle}
      contentContainerCss={styles.listStyle}
      css={styles.sliderContainerStyle}
      eventParams={sectionTrackingData}>
      {items.map((item, index) => (
        <li key={item.id} css={styles.listItemStyle}>
          {renderItem({ item, index })}
        </li>
      ))}
    </Slider>
  );
};
